<template>
  <div />
</template>

<script>
import { ApiMixin } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'

export default {
  name: 'MainLogout',
  mixins: [ApiMixin, NotificationMixin],
  mounted() {
    this.logout()
  },
  methods: {
    logout() {
      this.$store.commit('clearToken')
      this.$store.commit('clearCurrentUser')
      window.sessionStorage.clear()
      let redirectUrl = this.$route.query.redirectUrl
      this.$router
        .push({
          name: 'login',
          query: { redirectUrl }
        })
        .then(() => {
          let msgKey = this.$store.getters.getLogoutErrorMessageKey
          if (msgKey.length > 0) {
            this.displayError(this.$t(msgKey))
            this.$store.commit('clearLogoutErrorMessageKey')
          }
          this.$store.commit('setLogoutInProgress', false)
        })
        .catch(() => {})
    }
  }
}
</script>
